<template lang="html">
  <div :class="$style.preview">
    <div>
      <div class="btn-group">
        <IxButton large back @click="$router.push({name: 'dashboard'})">
          <IxRes>dlgCert.navigation.buttons.back</IxRes>
        </IxButton>
      </div>

      <h3>
        <IxRes :context="{org: selectedCompany.name, year: $route.params.year}">
          dlgCert.certificatePreview.header
        </IxRes>
      </h3>

      <a
        download target="_blank"
        data-ajax="false"
        :href="downloadUrl"
      >
        <IxRes>dlgCert.certificatePreview.downloadResults</IxRes>
      </a>
    </div>

    <LoadingSpinner
      v-if="!(result || error)"
      :class="$style.spinner"
      v-bind="{loading: !(result || error)}"
    />
    <HelpBox v-else-if="error" type="danger" large>
      <IxRes>dlgCert.certificatePreview.hints.previewCouldNotBeLoaded</IxRes>
    </HelpBox>
    <router-view v-else :data="result" />
  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'

import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'
import LoadingSpinner from '@components/LoadingSpinner'
import HelpBox from '@components/help/HelpBox'

export default {
  components: {
    IxButton,
    LoadingSpinner,
    HelpBox
  },
  props: {
    orgUnitId: String,
    year: Number
  },
  data () {
    return {
      result: null,
      error: false,
      showJson: false
    }
  },
  computed: {
    ...mapGetters('dlgCert', [
      'orgUnits'
    ]),
    selectedCompany () {
      return this.orgUnits.find(x => x.id === this.$route.params.orgUnitId)
    },
    downloadUrl () {
      return `/api/v2/dlg-cert/${this.orgUnitId}/certification/${this.year}/zip`
    }
  },
  async created () {
    try {
      const response = await axios.get(`/api/v2/dlg-cert/${this.orgUnitId}/certification/${this.year}`)
      this.result = response.data
    } catch (error) {
      this.error = true
      console.error(error)
      notifications.error('preview failed to load')
    }
  }
}
</script>

<style lang="scss" module>
.preview {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
